.outerWrapper {
    background-color:black;
    padding-bottom: 100px;
}

.wrapper {
    max-width: 1440px;
    margin-inline: auto;
    padding-top: 50px;
    padding-inline: 100px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    border-radius: 5px;
    user-select: none;

    &:focus {
        outline: 3px solid #e1567c;
    }


    &Text {
        padding-top: 10px;
        font-family: "comfortaa", sans-serif;
        font-weight: 700;
        font-size: 26px;
        line-height: 18px;
        color: #f0f0f0;
    }
}

.socials {
    display: flex;
    align-items: center;
    gap: 10px;

    &Icon {
        color: white;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: .2s ease-in-out;
        &:hover {
            color: #c0c0c0;
        }
    }
}

.rights {
    color: #c0c0c0;
    text-align: right;
    margin-top: 50px;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;

    &::selection {
        background-color: #fff;
        color: red;
    }
}


@media screen and (max-width: 575px) {
    .outerWrapper {
        padding-bottom: 30px;
    }

    .wrapper {
        padding-inline: 20px;
        padding-top: 30px;
    }

    .logo {

        &Text {
            font-size: 18px;
            line-height: 14px;
        }
    }

    .socials {
        &Icon {
            width: 30px;
            height: 30px;
        }
    }

    .rights {
        font-weight: 500;
        font-size: 11px;
        line-height: 11px;
    }
}