.outer {
    width: 100%;
    background-color: rgb(31, 22, 51);

    position: sticky;
    z-index: 20;
    top: 0;
}

.wrapper {
    max-width: 1440px;
    margin-inline: auto;
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    padding-inline: 100px;
}

.logo {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    border-radius: 5px;
    user-select: none;

    &:focus {
        outline: 3px solid #e1567c;
    }


    &Text {
        padding-top: 10px;
        font-family: "comfortaa", sans-serif;
        font-weight: 700;
        font-size: 26px;
        line-height: 18px;
        color: #f0f0f0;
    }
}

.nav {
    display: flex;
    align-items: center;
    gap: 4px;
    .navLink {
        color: white;
        text-transform: uppercase;
        font-family: 'comfortaa';
        font-weight: 700;
        font-size: 14px;
        padding: 12px 14px 7px;
        border-radius: 5px;
        transition: .2s ease-in-out;

        &:hover {
            // color: #c0c0c0;
            background-color: #6a5fc1;
        }
    }
}

.menu, .navButtons {
    display: none;
}

@media screen and (max-width: 1200px) {
    .wrapper {
        padding-inline: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 50px;
        // outline: auto;
    }

    .logoText {
        font-size: 20px;
        line-height: 14px;
    }

    .nav {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(31, 22, 51);
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding-top: 80px;
        transition: left .25s ease-in-out;

        align-items: flex-start;
        
        .navLink {
            width: calc(100% - 60px);
            border-radius: 0;
            padding: 20px 0px 20px 60px;
        }
    }
    .loginBtn {
        display: none;
    }

    .menu {
        display: flex;
        column-gap: 10px;
    }
    .burgerIcon {
        margin-top: 0px;
    }

    .btn {
        width: 100%;
    }

    .navButtons {
        position: absolute;
        padding-top: 15px;
        height: 100vh;
        width: 60px;
        right: 0px;
        top: 0;
        background-color: #fff;
        z-index: 20;

        background-image: linear-gradient(
            120deg,
            #831f31,		
            #771c5c 25%,
            #2b1e8b 150%,
        );

        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        .closeBtn {
            opacity: .7;
            fill: #f0f0f0;
            width: 36px;
            height: 36px;
            border-radius: 100px;
        }

        .profileBtnBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 10px;
            color: #f0f0f0;
            font-weight: 700;
        }

        .profileBtn {
            width: 35px;
            height: 35px;
            fill: #f0f0f0;
            opacity: .7;
            border-radius: 100px;
            font-size: 10px;
        }
    }

    .navHider {
        left: -100%;
    }

}