.btn {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    transition: .2s ease-in-out;
    user-select: none;

    background-position: 98% 0;
    background-image: linear-gradient(120deg,#fa7faa,#ff9691 25%,#ffb287 50%,#fff 55%,#fff);
    background-size: 250% 100%;
    transition: background-position .12s linear;


    &:hover, &:focus {
        background-position: 2%;
    }
}