.title {
	color: #c0c0c0;
	text-align: center;
	margin-top: 50px;
	font-size: 22px;
}

.stack {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	gap: 50px;

	max-width: 1440px;
	padding-inline: 100px;
	margin-inline: auto;

	.icon {
		width: 100px;
		height: 100px;
		fill: #c0c0c0;
		transition: 0.2s ease-in-out;
	}

	.reactIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 3px solid #c0c0c0;
		border-radius: 100px;
		transition: 0.2s ease-in-out;
		width: 100px;
		height: 100px;

		.icon {
			width: 80px;
			height: 80px;
		}
	}

	.next {
		width: 100px;
		height: 100px;
		border-radius: 100px;
		overflow: hidden;
		background-color: #c0c0c0;
		border: 3px solid #c0c0c0;
		fill: rgb(31, 22, 51);
		transition: 0.2s ease-in-out;
	}
}

.tech {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	p {
		color: #c0c0c0;
		font-size: 18px;
	}

	&:hover {
		.reactIcon {
			border: 3px solid white;
		}

		.next {
			background-color: #fff;
			border: 3px solid white;
		}
		.icon {
			fill: white;
		}

		p {
			color: white;
			text-shadow: 0 0px 16px rgba(255, 255, 255, 0.6);
		}
	}
}

@media screen and (max-width: 1200px) {
	.title {
		font-size: 18px;
		line-height: 19px;
	}

	.stack {
		justify-content: space-between;
		padding-inline: 20px;
		max-width: 570px;
		gap: 10px;

		.icon {
			width: 50px;
			height: 50px;
			border-radius: 100px;
		}

		.reactIcon {
			border: 1.5px solid #c0c0c0;
			width: 50px;
			height: 50px;

			.icon {
				width: 40px;
				height: 40px;
			}
		}

		.next {
			width: 50px;
			height: 50px;
			border: 1.5px solid #c0c0c0;
		}
	}

	.tech {
		.reactIcon {
			margin-bottom: 5px;
		}
		p {
			font-size: 10px;
		}
	}
}
