.wrapper {
	display: flex;
	height: 300vh;
}

.leftside {
	background-color: #232325;
	height: 100%;
	width: 300px;

    .avatar {
        width: 200px;
        height: 200px;
        border-radius: 100%;
        background-image: var(--gradient-color);
        margin-inline: auto;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
