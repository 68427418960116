* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	border: none;
	outline: none;
	background: transparent;
	text-decoration: none;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--main-color: rgb(31, 22, 51);
	--gradient-color: linear-gradient(120deg, #c83852, #b44092 25%, #6a5fc1 50%, #452650 205%, #452650);
}

body::-webkit-scrollbar {
	width: 8px;
	background-color: rgb(51, 37, 80);

	&-thumb {
		background-image: linear-gradient(120deg, #c83852, #b44092 25%, #6a5fc1 50%, #452650 205%, #452650);
		border-radius: 2px;
	}
}

.body-without-scroll {
	overflow: hidden;
	padding-right: 8px;

	&::before {
		position: absolute;

	}

	&::-webkit-scrollbar {
		width: 8px;
		background-color: rgb(51, 37, 80);

		&-thumb {
			background-image: linear-gradient(120deg, #c83852, #b44092 25%, #6a5fc1 50%, #452650 205%, #452650);
			border-radius: 2px;
		}
	}
}

button {
	cursor: pointer;
	user-select: none;

	&:focus {
		outline: 3px solid #e1567c;
	}
}

.link {
	background: transparent;
	color: red;
}
