.bg {
	color: white;
	max-width: 1440px;
	margin-inline: auto;
	padding-bottom: 200px;
	position: relative;

	background-image: radial-gradient(
		41.11% 49.93% at 50% 49.93%,
		rgb(141, 84, 148) 0%,
		rgb(86, 50, 117) 52.26%,
		rgb(31, 22, 51) 100%
	);

	.plus {
		position: absolute;
		left: 150px;
		top: 240px;
		width: 20px;
		height: 2px;
		background-color: #c0c0c0;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 2px;
			height: 20px;
			background-color: #c0c0c0;
		}

		&:nth-child(2) {
			left: initial;
			right: 150px;
		}

		&:nth-child(3) {
			top: initial;
			bottom: 50px;
		}

		&:nth-child(4) {
			top: initial;
			bottom: 50px;
			left: initial;
			right: 150px;
		}
	}
}

.coloredButton {
	margin: 40px auto 0px;
	padding: 8px 30px;
	border-radius: 8px;
	background-position: 2% 0;
	background-size: 250% 100%;
	color: white;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.5px;
	transition: box-shadow 0.4s ease-in-out, background-image 0.3s;
	cursor: pointer;

	display: flex;
	align-items: center;
	gap: 13px;

	background-image: linear-gradient(
		120deg,
		#c83852,
		#b44092 25%,
		#6a5fc1 50%,
		#452650 55%,
		#452650
	);

	&:focus {
		outline: 3px solid #e1567c;
	}

	span {
		color: rgb(246, 255, 221);
	}

	&:hover {
		box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
	}

	&Icon {
		width: 13px;
		height: 13px;
		min-width: 13px;
		min-height: 13px;
	}
}

.title {
	font-size: 50px;
	line-height: 90px;
	font-family: 'poppins', sans-serif;
	font-weight: 800;
	text-align: center;
	margin-top: 10px;
	text-transform: uppercase;

	display: flex;
	justify-content: center;
	align-items: flex-start;

	&::selection {
		background-color: white;
		color: red;
	}

	span {
		font-size: 120px;
	}

	&Dot {
		color: red;
		overflow: hidden;
		height: 90px;

		.title {
			font-size: 100px;
			margin-top: -16px;
			&::selection {
				background-color: red;
				color: white;
			}
		}
	}
}

.subtitle {
	text-align: center;
	font-size: 18px;
	line-height: 26px;
	max-width: 1000px;
	margin-inline: auto;
	color: rgb(190, 190, 190);
	user-select: none;
	span {
		color: white;
	}
}

.codeImages {
	position: relative;
	margin-top: 80px;
	left: -80px;

	user-select: none;
	margin-inline: auto;
	width: fit-content;
}

.img {
	height: 400px;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.3);
	z-index: 10;
	border-radius: 8px;

	&:nth-child(2) {
		position: absolute;
		top: 100px;
		left: 100px;
	}
}

.btns {
	width: fit-content;
	margin-inline: auto;
	margin-top: 40px;
	display: flex;
	gap: 20px;

	button {
		padding: 10px 20px;
		font-weight: 700;
		font-size: 15px;
		border-radius: 4px;
		height: 40px;
	}

	button:nth-child(1) {
		color: rgb(0, 0, 0);
		background-color: white;
	}

	button:nth-child(2) {
		color: white;
		border: 2px solid #e1567c;

		background-position: 98% 0;
		background-image: linear-gradient(
			120deg,
			#fa7faa,
			#ff9691 25%,
			#ffb287 50%,
			transparent 55%,
			transparent
		);
		background-size: 250% 100%;
		transition: background-position 0.12s linear;

		a {
			color: white;
			line-height: 0;
			font-size: 15px;
			line-height: 15px;
			height: 9px;
		}

		&:hover {
			background-position: 2% 100%;
		}

		&:focus {
			outline: 2px solid #e1567c;
		}
	}
}

@media screen and (max-width: 1200px) {
	.coloredButton {
		width: 100%;
		border-radius: 0;
		margin: 0;
		font-size: 10px;
		padding: 10px;
		justify-content: center;

		&Icon {
			display: none;
		}
	}

	.title {
		font-size: 36px;
		margin-top: 60px;
		line-height: 40px;

		&Dot {
			display: none;
		}
	}

	.subtitle {
		font-size: 14px;
		line-height: 18px;
		padding-inline: 20px;
		margin-top: 30px;
	}

	.codeImages {
		display: none;
	}

	.bg {
		padding-bottom: 50px;
		.plus {
			left: 10px;
			top: -34px;
			width: 10px;
			height: 1px;

			&::before {
				height: 10px;
				width: 1px;
			}

			&:nth-child(2) {
				left: initial;
				right: 10px;
			}
	
			&:nth-child(3) {
				bottom: 20px;
			}
	
			&:nth-child(4) {
				left: initial;
				right: 10px;
				bottom: 20px;
			}
		}


	}
}

