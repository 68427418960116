.wrapperOuter {
	width: 100vw;
	height: 100vh;
	position: fixed;
	overflow: auto;
	z-index: 100;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.wrapper {
	background-color: #fff;
	width: 800px;
	height: 500px;
	border-radius: 10px;
	margin-inline: auto;
	margin-top: 50px;
}
