.wrapper {
    margin-top: 50px;
    clip-path: polygon(0 0, 100% 50px, 100% calc(100% - 50px), 0% 100%);
    // clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
	background-image: linear-gradient(
        120deg,
		#831f31,		
		#771c5c 25%,
        #2b1e8b 150%,
	);
    background-position: 250% 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.service {
    max-width: 1440px;
    margin-inline: auto;
    padding: 20px 100px;

    display: flex;
    gap: 50px;
}


.titles {
    text-align: right;
}

.title4 {
    color: #ebebeb;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    text-shadow: 0px 0px 10px rgb(255, 255, 161);

    font-weight: 400;
}

.title {
    color: #f0f0f0;
    font-size: 36px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contentSide {
    width: 360px;
    display: flex;
    flex-direction: column;
}

.tab {
    cursor: pointer;
    margin-top: 10px;
    border-radius: 10px;
    padding: 16px 20px;
    background-color: rgb(255, 255, 255,.3);
    box-shadow: 0 0 20px 10px rgba(141, 141, 141, 0.1);
    object-fit: cover;
    height: 100%;
    height: 60px;
    transition:  .2s ease-in-out;
    border: 5px solid rgb(255, 255, 255,.0);
    
    &:hover {
        background-color:rgb(255, 255, 255, .2);
    }

    .tabText {
        padding-top: 10px;
        color: #232325;
    transition:  .2s ease-in-out;
    font-size: 16px;
    }

    &Name {
        color: rgb(255, 255, 255);
        font-weight: 700;
        transition:  .2s ease-in-out;
        font-size: 18px;
    }
}

.tabActive {
    background-color: #ffffff;
    transition:  .2s ease-in-out;
    border: 5px solid rgba(40, 40, 168, 0.541);
    height: 100%;
    
    .tabName {
        color: rgb(41, 43, 155);
    }

    .tabText {
        max-height: initial;
    }

    &:hover {
        background-color: white;
    }
}

.hider {
    overflow: hidden;
    transition:  .2s ease-in-out;
}







.code {
    background-color: rgba(255,255,255,.1);
    border-radius: 10px;
    padding: 20px;
    width: calc(100% - 410px);
    height: 380px;
    color: rgb(255, 176, 176);
    box-shadow: 0 0 20px 10px rgba(141, 141, 141, 0.1);
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .image {
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 20px 10px rgba(238, 255, 0, 0.122)
    }
}



@media screen and (max-width: 1200px) {
    .wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        clip-path: polygon(0 0, 100% 20px, 100% calc(100% - 20px), 0% 100%);
    }

    .service {
        padding-inline: 20px;
        justify-content: center;
    }


    .code {
        display: none;
    }
}