.wrapperOuter {
	background-color: var(--main-color);
}
.wrapper {
	max-width: 1440px;
	height: 100%;

	margin-inline: auto;
	padding-inline: 20px;
}

.titles {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}

:root {
	--test: rgba(116, 116, 116, 0.418);
}

.title {
	-webkit-text-stroke: 1px var(--test);
	color: transparent;
	font-family: 'Poppins';
	font-size: 9vw;
	line-height: 7vw;
	text-transform: uppercase;
	text-align: center;
	width: fit-content;
	opacity: 0.8;
	position: relative;
	left: 0;
	opacity: 1;
	z-index: 1;
	transition: 0.3s ease-in-out;
	white-space: nowrap;

	&:nth-child(2) {
		-webkit-text-stroke: 2px var(--test);
	}

	&:nth-child(3) {
		-webkit-text-stroke: 3px var(--test);
	}

	&:nth-child(4) {
		-webkit-text-stroke: 4px var(--test);
	}
}

.visibleTitle {
	z-index: 3;
	color: rgb(252, 252, 252);
	font-size: 5vw;
	line-height: 3vw;
	font-weight: 900;
	text-transform: uppercase;
	position: absolute;
	left: 0;
	top: 40%;
	transform: translate(5%, -50%);

	span {
		font-size: 5vw;
		line-height: 4vw;
		white-space: nowrap;
		line-height: 9vw;
		color: white;
		background-color: red;
	}
}

.section {
	position: relative;
	background-color: #fff;
	&:nth-child(2) {
		position: relative;
		z-index: 3;
		margin-top: -5px;
		height: 100vh;
		background-color: rgb(255, 255, 255);
	}
}
.advertWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h1 {
		color: rgb(49, 49, 49);
		a {
			text-transform: uppercase;
			font-weight: 800;
			color: #e80d9e;
			font-size: 46px;
			&:hover {
				text-decoration: underline;
				color: #c01085;
			}
		}
	}

	.leftSide {
		width: 50%;
		min-width: 50%;
		img {
			object-fit: cover;
			width: 100%;
			box-shadow: 0 0 20px 10px rgba(148, 148, 148, 0.5);
			border-radius: 10px;
		}
	}

	p {
		margin-top: 20px;
		font-size: 16px;
		font-weight: 500;
		max-width: 600px;
	}

	button {
		width: 100%;
		margin-top: 100px;
		background-color: rgb(34, 34, 34);
		color: #f0f0f0;
		padding: 12px;
		border-radius: 5px;
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;
		border: 1px solid black;

		&:hover {
			color: black;
			background-color: white;
			box-shadow: 0 0 10px 3px rgb(228, 228, 228);
		}
	}
}

@media screen and (max-width: 1200px) {
	.wrapper {
		padding-inline: 20px;
	}
	.title {
		opacity: 0;
		line-height: 8vw;
	}

	.advertWrapper {
		flex-direction: column-reverse;
		h1 {
			margin-top: 20px;
			margin-bottom: 20px;
			a {
				text-align: center;
				font-size: 32px;
			}
		}
		button {
			margin-top: 30px;
		}
		p {
			margin-inline: auto;
			font-size: 14px;
		}
		.leftSide {
			width: calc(100% + 40px);
			margin-left: -20px;
			img {
				box-shadow: none;
				border-radius: 0;
			}
		}
	}

	.section {
		position: relative;
		padding-bottom: 50px;
		&:nth-child(2) {
			height: initial;
		}
	}
}
