.wrapper {
	max-width: 1440px;
	margin-inline: auto;
	padding-inline: 100px;
	padding-bottom: 50px;
	display: flex;
	justify-content: space-between;
	margin-top: 70px;
}

.title {
	font-size: 42px;
	line-height: 50px;
	height: 50px;
	font-weight: 700;
	color: white;
}

.point {
	border-bottom: 1px solid rgb(255, 255, 255);
	width: 830px;
	// overflow: hidden;

	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: white;
		cursor: pointer;
		height: 50px;

		&:hover {
			svg {
				fill: #c0c0c0;
			}
		}

		svg {
			width: 38px;
			height: 38px;
			transition: 0.2s ease-in-out;
		}

		span {
			user-select: none;
			font-size: 18px;
			line-height: 24px;
			font-weight: 500;
		}
	}

	.text {
		color: #c0c0c0;
		padding-top: 10px;
		padding-bottom: 20px;
	}
}
@media screen and (max-width: 1200px) {
	.wrapper {
		padding-inline: 20px;
		flex-direction: column;
		row-gap: 10px;
	}

	.point {
		width: 100%;

		.title {
			span {
				font-size: 15px;
				line-height: 18px;
			}
			svg {
				min-width: 30px;
				width: 30px;
				min-height: 30px;
				height: 30px;
			}
		}
	}
}
